import React, { useEffect, useState } from 'react';
import Api from '../../../data/API';
import NoticeSettingForm from '../../foundationSetup/components/NoticeSettingForm';
import { Spin } from 'antd';
import { getWuyeProjectInfo } from '../../../services/FoundationSetupService';
import CallNoticeForm from '../components/CallNoticeForm';

const NoticeSet: React.FC = (props: any) => {
	const [projectInfo, setProjectInfo] = useState({});
	const [dataLoaded, setDataLoaded] = useState(false);
	const [templateInfo, setTemplateInfo] = useState({})

	useEffect(() => {
		getProjectInfo();
	}, []);

	const getProjectInfo = async () => {
		const res = await getWuyeProjectInfo({ wuyeUuid: localStorage.getItem('wyUuid') });

		// Api.postWithAuth('/api/wuye/get_wuye_projectInfo',
		//                  {wuyeUuid: localStorage.getItem('wyUuid')}).then(res => {
		//     console.log('信息', res);
		if (res.err === 0) {
			setProjectInfo(res.data);
			if(res.smsTemplate) {
				setTemplateInfo(res.smsTemplate)
			}
			setDataLoaded(true);
		}
		// });
	};

	const renderSettingForm = () => {
		if (dataLoaded) {
			return <NoticeSettingForm projectInfo={projectInfo} />;
		} else {
			return <Spin />;
		}
	};

	const renderCallNoticeForm = () => {
		if (dataLoaded) {
			return <CallNoticeForm projectInfo={projectInfo} templateInfo={templateInfo}  />;
		} else {
			return <Spin />;
		}
	};


	return (
		<div>
			<h3>催缴通知设置</h3>
			<div style={{ backgroundColor: '#fff', padding: 20 }}>{renderCallNoticeForm()}</div>

			<h3 style={{marginTop: 10}}>发送邮件设置</h3>
			<div style={{ backgroundColor: '#fff', padding: 20 }}>{renderSettingForm()}</div>
		</div>
	);
};
export default NoticeSet;
