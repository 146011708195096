import * as React from 'react';
import MiniApp, { MiniAppMenu, MiniAppRoute } from '../../util/MiniApp';
import OptionReport from "../statistics/OptionReport";
import PropertyReport from "../statistics/PropertyReport";
import EnergyConsumptionReport from "../statistics/EnergyConsumptionReport";
import IndustrialOperation from "../statistics/IndustrialOperation";
import CurrentPlatform from "../controlPlatform/CurrentPlatform";

export default function DataBoardMiniApp() {
	const rootUrl = '/data-board';
	const menus: MiniAppMenu[] = [
		{
			authority: 'statistics:option_report',
			path: 'option_report',
			name: '综合运营看板',
			component: () => <OptionReport/>
		},
		{
			authority: 'statistics:property_report',
			path: 'property_report',
			name: '物业运营看板',
			component: () => <PropertyReport/>
		},
		{
			authority: 'statistics:energy_consumption_report',
			path: 'energy_consumption_report',
			name: '综合能耗看板',
			component: () => <EnergyConsumptionReport/>
		},
		{
			authority: 'statistics:industrial_operation_report',
			path: 'industrial_operation_report',
			name: '产业运营看板',
			component: () => <IndustrialOperation/>
		},
		{
			authority: 'current_platform',
			path: 'current-platform',
			name: '通行概览',
			component: () => <CurrentPlatform/>
		},
	];

	const routes: MiniAppRoute[] = [
	];

	return <MiniApp miniAppName={'资产管理'} rootUrl={rootUrl} menus={menus} routes={routes} />;
}
