import React, { useEffect, useState } from 'react';
import { Button, Card, Col, DatePicker, message, Modal, Radio, Row, Select, Tooltip, TreeSelect } from 'antd';
import { getCompanyEmpData, getDevices, getProjSendKeyConfig } from '../../../services/ZhiNengMenJinService';
import { QuestionCircleOutlined } from '@ant-design/icons/lib';
import API from '../../../data/API';
import { getDepartment } from '../../../services/PermItemService';
import GSTable from '../../../components/GSTable';
let moment = require('moment');

const SendKey: React.FC = () => {
	useEffect(() => {

		getDeviceList();
		getEmpUserList();
		getThirdCompanyEmpData()
		getProjConfig()

	}, []);
	const Option = Select.Option;
	const RadioGroup = Radio.Group;
	const { RangePicker } = DatePicker;
	const [deviceIds, setDeviceIds] = useState([]);
	const [devices, setDevices] = useState([]);
	const [contact, setContact] = useState([]);
	const [keyType, setKeyType] = useState('anytime');
	const [showRow, setShowRow] = useState(true);
	const [loading, setLoading] = useState(false);
	const [type, setType] = useState(-1);
	const [accountType, setAccountType] = useState(1);
	const [empUserList, setEmpUserList] = useState([]);
	const [thirdCompayEmpList, setThirdCompayEmpList] = useState([]);
	const [startTime, setStartTime] = useState(moment().format('YYYY-MM-DD HH:mm'));
	const [expireTime, setExpireTime] = useState("");
	const [sendKeyConfig, setSendKeyConfig] = useState("");
	const [resultVisible, setResultVisible] = useState(false);
	const [errData, setErrData] = useState([]);
	const [deviceEnum, setDeviceEnum] = useState({});
	const infoColumns = [
		{
			title: '接收账号',
			dataIndex: 'account'
		},
		{
			title: '设备ID',
			dataIndex: 'deviceId',
			valueEnum: deviceEnum
		},
		{
			title: '失败原因',
			dataIndex: 'errMsg'
		},
	];

	const getProjConfig = async () => {
		let rsp = await getProjSendKeyConfig({});
		if (rsp.err === 0) {
			setSendKeyConfig(rsp.data)
		}
	}
	const getDeviceList = async () => {
		const res = await getDevices({});
		console.log('res:::', res);
		if (res.err === 0) {
			setDevices(res.data);
			let deviceEnum: any = {};
			res.data.map((item: any) => {
				item.children.map((child: any) => {
					deviceEnum[child.value.split(":::")[1]]= child.title;
				})
			})
			setDeviceEnum(deviceEnum)
		}
	};
	const getThirdCompanyEmpData = async () => {
		const res = await getCompanyEmpData({})
		if (res.err === 0) {
			setThirdCompayEmpList(res.data)
		}
	}
	const getEmpUserList = async () => {
		const data = {
			companyUuid: API.getCompanyUuid(),
			type: 'sendKey',
		};
		const res = await getDepartment(data);
		if (res.err == 0) {
			setEmpUserList(res.data);
			console.log('empUserList:::::', res);
		}
	};
	const onchageDeviceIds = (ev: any) => {
		setDeviceIds(ev);
	};

	const getContact = (e: any) => {
		let value: any = [];
		e.map((item: string) => {
			if(item.includes(":::")) {
				value.push(item.split(":::")[1])
			} else {
				value.push(item)
			}
		})
		setContact(value);
	};
	const Jurlsdiction = (ev: any) => {
		setKeyType(ev);
	};

	const setDateByRadio = (e: any) => {
		console.log('e:::::::::::', e.target.value);
		setType(e.target.value);
		let startTime1 = moment().format('YYYY-MM-DD HH:mm');
		let endTime = '';
		switch (e.target.value) {
			case 4:
				endTime = moment().add(1, 'd').format('YYYY-MM-DD HH:mm');
				break;
			case 1:
				endTime = moment().add(1, 'y').format('YYYY-MM-DD HH:mm');
				break;
			case 2:
				endTime = moment().add(30, 'd').format('YYYY-MM-DD HH:mm');
				break;
			case 3:
				startTime1 = '1111-01-01 00:00';
				endTime = '9999-01-01 00:00';
				break;
			default:
				endTime = '';
				break;
		}
		setStartTime(startTime1);
		setExpireTime(endTime);
		console.log('startTime::', startTime, expireTime);
		setShowRow(e.target.value === -1);
	};

	/** 发送钥匙 */
	const callData = () => {
		let deviceId = deviceIds.toString();
		let contact1 = contact.toString().replace(/[\r\n]/g, "");
		console.log("contacts:::",contact1)
		if (deviceId === undefined || deviceId === '') {
			message.warning('发送的设备不能为空');
			return;
		}

		if (contact1 === undefined || contact1 === '') {
			message.warning('接收人不能为空');
			return;
		}
		if (!accountType) {
			message.warning('请选择接收账号');
			return;
		}
		if (!expireTime) {
			message.warning('请填写截止日期');
			return;
		}
		setLoading(true);
		let url = '/send_keys';
		let datas = { contacts: '', device_id: '', key_type: '', start_time: '', expire_time: '' , account_type: 1};
		datas.contacts = contact1;
		datas.device_id = deviceId;
		datas.key_type = keyType || 'anytime';
		datas.start_time = startTime + ':00';
		datas.expire_time = expireTime + ':59';
		datas.account_type = accountType;
		console.log('发送信息', datas);

		API.postWithAuth(url, datas)
			.then((res) => {
				setLoading(false);
				if (res.err === 0) {
						setContact([]);
						setDeviceIds([]);
						setType(-1);
						setAccountType(1)
						setStartTime(moment().format('YYYY-MM-DD HH:mm')) ;
						setExpireTime('') ;
						setShowRow(true);
						if (res.errData && res.errData.length > 0) {
							setErrData(res.errData);
							setResultVisible(true)
						} else {
							message.success(res.msg);
						}

				}else {
					message.warning('发送结果' + (res as any).msg);
				}
			})
			.catch(() => {
				setLoading(false);
			});
	};

	const setExpiretime = (date: any, dataToString: any) => {
		console.log(date, dataToString);
		setStartTime(dataToString[0]);
		setExpireTime(dataToString[1]);
	};
	return (
		<div>
			<Row gutter={16}>
				<Col xs={24} sm={24} md={18} lg={18} xl={16} xxl={12}>
					<Card title={'发送钥匙'}>
						<Row gutter={8} justify="center" align={'middle'} className={'antdRow'}>
							<Col span={4} style={{ textAlign: 'right' }}>
								选择设备：
							</Col>
							<Col span={14}>
								<TreeSelect
									key={''}
									style={{ width: '100%' }}
									dropdownStyle={{ maxHeight: 300, overflow: 'auto' }}
									treeData={devices}
									allowClear={true}
									multiple={true}
									treeNodeFilterProp={'title'}
									treeCheckable={true}
									showSearch={true}
									value={deviceIds}
									onChange={onchageDeviceIds}
								/>
							</Col>
						</Row>
						<Row gutter={8} justify="center" align="middle" className="antdRow">
							<Col span={4} style={{ textAlign: 'right' }}>
								接收账号：
							</Col>
							<Col span={14}>
								<RadioGroup
									value={accountType}
									onChange={(value: any) => {
										setAccountType(value.target.value);
									}}
								>
									<Radio disabled={!!sendKeyConfig && !sendKeyConfig.includes("员工账号")} value={1}>员工账号<Tooltip
										placement="top"
										title={"输入的账号需要已经在组织架构中录入"}
									>
										<QuestionCircleOutlined />
									</Tooltip>
									</Radio>
									<Radio disabled={!!sendKeyConfig && !sendKeyConfig.includes("按部门选择")} value={2}>按部门选择</Radio>
									<Radio disabled={!!sendKeyConfig && !sendKeyConfig.includes("按入驻企业")} value={3}>按入驻企业</Radio>
									<Radio disabled={!!sendKeyConfig && !sendKeyConfig.includes("输入账号")} value={4}>输入账号<Tooltip
										placement="top"
										title={"不存在的用户将新创建"}
									>
										<QuestionCircleOutlined />
									</Tooltip></Radio>
								</RadioGroup>
							</Col>
						</Row>
						<Row gutter={8} justify="center" align={'middle'} className={'antdRow'}>
							<Col span={4}></Col>
							<Col span={14}>
								{(accountType === 1 || accountType === 4)  && (
									<Select
										mode="tags"
										key={contact.toString()}
										style={{ width: '100%' }}
										value={contact}
										onChange={getContact}
										tokenSeparators={[',', '\n', '，', '\\', '\t', '\r\n', ' ']}
									/>
								)}
								{ accountType === 2 && (
									<TreeSelect
										treeData={empUserList}
										treeCheckable={true}
										multiple={true}
										value={contact}
										onChange={getContact}
										treeNodeFilterProp="title"
										searchPlaceholder="请选择"
										dropdownStyle={{ overflow: 'auto' }}
										style={{ width: '100%' }}
									/>
								)
								}
								{
									accountType === 3 && <TreeSelect
										treeData={thirdCompayEmpList}
										treeCheckable={true}
										multiple={true}
										defaultValue={contact}
										onChange={getContact}
										treeNodeFilterProp="title"
										searchPlaceholder="请选择"
										dropdownStyle={{ overflow: 'auto' }}
										style={{ width: '100%' }}
									/>
								}
							</Col>
						</Row>
						<Row gutter={8} className={'antdRow'} align={'middle'} justify="center">
							<Col span={4} style={{ textAlign: 'right' }}>
								权限{' '}
								<Tooltip
									placement="top"
									overlayInnerStyle={{width: 450}}
									title={
										<div >
											<span>普通用户：仅可以使用，不可以查看设备使用数据，授权、删除钥匙</span>
											<br />
											{/* <span>管理和查看：可以发送，删除钥匙，可以查看使用记录；</span> */}
											{/* <br /> */}
											<span>管理：可以授权，删除钥匙，不可以使用和查看设备使用数据；</span>
											<br />
											<span>查看：仅可以查看设备使用数据</span>
											<br />
											<span>超级管理员：可以使用设备，授权、删除钥匙，查看设备使用数据</span>
											<br />
										</div>
									}
								>
									<QuestionCircleOutlined />
								</Tooltip>{' '}

							</Col>
							<Col span={14}>
								<Select
									showSearch={true}
									style={{ width: '100%' }}
									placeholder="请选择"
									optionFilterProp="children"
									onChange={Jurlsdiction}
									value={keyType}
									defaultValue={keyType}
								>
									<Option value="anytime">普通用户</Option>
									<Option value="manage">管理</Option>
									<Option value="look">查看</Option>
									<Option value="use_look">使用和查看</Option>
									<Option value="manage_look">管理和查看</Option>
									<Option value="use_manage">使用和管理</Option>
									<Option value="admin">超级管理员</Option>
								</Select>
							</Col>
						</Row>
						<Row className={'antdRow'} align={'middle'} gutter={8} justify="center">
							<Col span={4} style={{ textAlign: 'right' }}>
								有效期：
							</Col>
							<Col span={14}>
								<RadioGroup onChange={setDateByRadio} defaultValue={-1} value={type}>
									<Radio value={-1}>自定义</Radio>
									<Radio value={4}>
										<Tooltip overlay={'自当前时间开始1天'}>1天</Tooltip>
									</Radio>
									<Radio value={2}>
										<Tooltip overlay={'自当前时间开始30天'}>30天</Tooltip>
									</Radio>
									<Radio value={1}>
										<Tooltip overlay={'自当前时间开始一年'}>一年</Tooltip>
									</Radio>
									<Radio value={3}>永久</Radio>
								</RadioGroup>
							</Col>
						</Row>

						<Row className={'antdRow'} justify="center" align={'middle'}>
							<Col span={14} offset={4}>
								<RangePicker
									disabled={[!showRow, !showRow]}
									style={{ width: '100%' }}
									showTime={{ format: 'HH:mm' }}
									format="YYYY-MM-DD HH:mm"
									placeholder={['开始时间', '截止时间']}
									value={[startTime ? moment(startTime, 'YYYY-MM-DD HH:mm'): moment(), expireTime ? moment(expireTime, 'YYYY-MM-DD HH:mm') : undefined]}
									onChange={setExpiretime}
								/>
							</Col>
						</Row>
						<Row className={'antdRow'} justify="center" align={'middle'}>
							<Col span={14} offset={4}>
								<Button loading={loading} type={'primary'} onClick={callData}>
									发送
								</Button>
							</Col>
						</Row>
						{/*<Row className={'antdRow'}  gutter={8}>*/}
						{/*	<Col span={4}>*/}
						{/*		    */}
						{/*	</Col>*/}
						{/*	<Col>*/}
						{/*		*/}
						{/*	</Col>*/}
						{/*</Row>*/}
					</Card>
				</Col>
			</Row>
			<Modal title={'结果'} visible={resultVisible} footer={null} onCancel={() => {setResultVisible(false)}}>
				<GSTable rowKey={(record: any) => {return JSON.stringify(record)}} columns={infoColumns} dataSource={errData} options={false}/>
			</Modal>
		</div>
	);
};
export default SendKey;
