import { Button, Col, Form, FormInstance, Input, message, Row, Switch } from 'antd';
import React, { useState } from 'react';
import { updateCallNoticeSet } from '../../../services/CallNoticeService';
interface Params {
	projectInfo: {};
	templateInfo: {}

}
const CallNoticeForm: React.FC<Params> = (props: any) => {
	const { projectInfo = {}, templateInfo = {} } = props;
	const [loading, setloading] = useState(false);
	const [form] = Form.useForm<FormInstance>();
	const formLayout = {
		labelCol: { span: 6 },
		wrapperCol: { span: 16 },
	};
	const FormItem = Form.Item;

	const saveItem = () => {
		form.validateFields().then(async (data: any) => {
			const formData = {
				...data,
			};

			const res = await updateCallNoticeSet(formData);
			if (res.err == 0) {
				message.success('更新成功');
			} else {
				message.error(res.msg);
			}
		});
	};
	return (
		<>
			<Row>
				<Col span={12}>
					<Form
						form={form}
						initialValues={{
							callNoticeSsm: projectInfo.callNoticeSsm == undefined ? false : projectInfo.callNoticeSsm,
						}}
						{...formLayout}
					>
						<FormItem
							label={'催缴短信通知'}
							tooltip="开启后在发送催缴通知时发送短信"
							name={'callNoticeSsm'}
							valuePropName="checked"
						>
							<Switch></Switch>
						</FormItem>
					</Form>
					<Row justify={'start'} style={{ alignItems: 'center' }}>
						<Col span={6} style={{textAlign: "right"}}>模版内容：</Col>
						<Col span={18}>{templateInfo.content || '未设置'}</Col>
					</Row>
					<Row justify={'start'} style={{ alignItems: 'center' }}>
						<Col span={6}></Col>
						<Col span={5}>
							<Button type="primary" onClick={saveItem} loading={loading}>
								保存
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
		</>
	);
};
export default CallNoticeForm;
